import React, { useCallback } from 'react';
import style from './bildnachweis-page.module.scss';
import classNames from 'classnames';
import bildnachweise from '../../content/bildnachweis.json';

interface Bildnachweis {
  title: string;
  link: string;
  urheber: string;
}

export const BildnachweisPage: React.FunctionComponent = () => {
  const openLink = useCallback((link: string) => {
    window.open(link);
  }, []);

  return (
    <div className={classNames(style.wrapper)}>
      <div className={classNames(style.content)}>
        <h1 className={classNames(style.heading)}>Bildnachweis</h1>
        <div className={classNames('row around-xs', style.container)}>
          {bildnachweise.map((item: Bildnachweis) => (
            <div
              key={item.title}
              className={classNames(style.item, 'col-xs-12 col-sm-6')}
              onClick={() => openLink(item.link)}>
              <div className={classNames(style.inner)}>
                Adobe Stock: {item.title}, {item.urheber}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BildnachweisPage;
