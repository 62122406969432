import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  RouteComponentProps
} from 'react-router-dom';
import Headline from './components/headline/headline';
import { useDispatch } from 'react-redux';

/* Pages */
import HomePage from './pages/home-page/home-page';
import Footer from './components/footer/footer';
import TeamPage from './pages/team-page/team-page';
import PortfolioPage from './pages/portfolio-page/portfolio-page';
import PortfolioCategoryPage from './pages/portfolio-category-page/portfolio-category-page';
import VemaService from './services/vema/vema-service';
import VemaApiResult from './types/vemaAPI';
import {
  transformVemaCategories,
  transfromVemaLandingspages
} from './utils/vema-utils';
import { SetLandingPageDataAction } from './redux/actions';
import BildnachweisPage from './pages/bildnachweis-page/bildnachweis-page';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    VemaService.getInstance()
      .getLandingpages()
      .then((response: VemaApiResult) => {
        const cats = transformVemaCategories(response, [
          'Newsletter',
          'Zielgruppe',
          'Anlass'
        ]);
        dispatch(
          SetLandingPageDataAction(
            transfromVemaLandingspages(response, cats),
            cats
          )
        );
      });
  }, [dispatch]);

  return (
    <Router>
      <Headline />
      <Route
        exact
        path='/team/:teamMember?'
        render={(props: RouteComponentProps) => (
          <TeamPage {...props.match.params} />
        )}
      />
      <Route exact path='/portfolio' render={() => <PortfolioPage />} />
      <Route
        exact
        path='/portfolio/:category'
        render={(props: RouteComponentProps) => (
          <PortfolioCategoryPage {...props.match.params} />
        )}
      />
      <Route exact path='/bildnachweis' component={BildnachweisPage} />
      <Route exact path='/' component={HomePage} />
      <Footer />
    </Router>
  );
};

export default App;
