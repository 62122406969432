import { VemaEntry } from '../types/vemaAPI';
import { createStore } from 'redux';
import applicationReducer from './reducer';

export interface ApplicationState {
  landingPages: VemaEntry[];
  portfolioCategories: string[];
  loading: boolean;
}

export const store = createStore(applicationReducer);
