import { Action } from 'redux';
import { VemaEntry } from '../types/vemaAPI';

interface SetLandingPageData extends Action {
  type: 'setLandingPageData';
  payload: {
    landingPages: VemaEntry[];
    categories: string[];
  };
}

export const SetLandingPageDataAction = (
  landingPages: VemaEntry[],
  categories: string[]
) => ({
  type: 'setLandingPageData',
  payload: {
    landingPages,
    categories
  }
});

export type Actions = SetLandingPageData;
