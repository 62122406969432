import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import style from './portfolio-page.module.scss';
import classNames from 'classnames';
import Tile, { FontSize } from '../../components/tile/tile';
import { categoryImageHelper, Size } from '../../utils/image-utils';
import { translateCategory } from '../../utils/translation-utils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux';

const PortfolioPage: React.FunctionComponent = () => {
  const categories = useSelector(
    (state: ApplicationState) => state.portfolioCategories
  );
  const loading = useSelector((state: ApplicationState) => state.loading);
  const history = useHistory();

  const onChangeFilter = useCallback(
    (category: string) => {
      history.push(`/portfolio/${encodeURI(category)}/`);
    },
    [history]
  );

  return (
    <div className={classNames(style.wrapper)}>
      <h1 className={classNames(style.heading)}>Unsere Leistungen</h1>
      {loading && (
        <div className={classNames('row', 'around-xs')}>
          <div className={classNames(style.loader)}> </div>
        </div>
      )}
      <div className={classNames('row', 'between-xs', style.container)}>
        {categories.map((item: string) => {
          return (
            <div className={'col-xs-12 col-sm-6 col-md-3'} key={item}>
              <Tile
                title={translateCategory(item)}
                image={categoryImageHelper(item, Size.small)}
                fontSize={FontSize.Small}
                onClick={() => onChangeFilter(item)}
                backgroundColor={'#ffffff'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortfolioPage;
