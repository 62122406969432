import React from 'react';
import classNames from 'classnames';
import style from './home-page.module.scss';
import Tile, { FontSize } from '../../components/tile/tile';
import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import image from './image.jpeg';
import leistungen from './leistungen-small.jpg';
import ansprechpartner from './ansprechpartner-small.jpg';
import { getBusinessCard } from '../../utils/businesscard-utils';
interface HomePageProps {
  referer?: string;
}

class HomePage extends React.Component<HomePageProps> {
  render() {
    return (
      <div className={classNames(style.wrapper)}>
        <div className={classNames(style.imageContainer)}>
          <img
            src={image}
            alt={'sleep easy...'}
            className={classNames(style.image)}
          />
          <div className={classNames(style.slogan)}>
            sleep easy...
            <br /> sorgenfrei den Tag geniessen
          </div>
        </div>
        <div className={classNames('row around-xs', style.links)}>
          <div
            className={classNames('col-xs-12 col-sm-6 col-md-4', style.item)}>
            <NavLink to={'/portfolio'}>
              <Tile
                title={'unsere Leistungen'}
                image={leistungen}
                fontSize={FontSize.Large}
              />
            </NavLink>
          </div>

          <div
            className={classNames('col-xs-12 col-sm-6 col-md-4', style.item)}>
            <NavLink to={'/team'}>
              <Tile
                title={'unser Team'}
                image={ansprechpartner}
                fontSize={FontSize.Large}
              />
            </NavLink>
          </div>

          <div
            className={classNames('col-xs-12 col-sm-6 col-md-4', style.item)}>
            <div
              className={classNames(style.contact)}
              onClick={() => window.open(getBusinessCard('alvaro'))}>
              <strong>alvaro Versicherungsmakler GmbH</strong>
              <br />
              Augasse 14
              <br />
              63741 Aschaffenburg
              <br />
              <br />
              <FontAwesome
                name={'phone'}
                className={classNames(style.icon)}
              />{' '}
              06021 / 580118 - 0<br />
              <FontAwesome
                name={'fax'}
                className={classNames(style.icon)}
              />{' '}
              06021 / 580118 - 20
              <br />
              <FontAwesome
                name={'envelope'}
                className={classNames(style.icon)}
              />{' '}
              info@alvaro-versicherungsmakler.de
              <br />
              <FontAwesome
                name={'globe'}
                className={classNames(style.icon)}
              />{' '}
              https://alvaro-versicherungsmakler.de
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
