import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TeamMember, {
  TeamMemberProps
} from '../../components/team-member/team-member';
import style from './team-page.module.scss';
import teammembers from '../../content/team.json';
import classNames from 'classnames';
import { getTeamMemberId } from '../../utils/businesscard-utils';

interface TeamPageProps {
  teamMember?: string;
}

export const TeamPage: React.FunctionComponent<TeamPageProps> = (
  props: TeamPageProps
) => {
  const { teamMember } = props;
  const [currentMember, setCurrentMember] = useState<TeamMemberProps>(
    teammembers[0]
  );
  const history = useHistory();

  useEffect(() => {
    if (teamMember)
      setCurrentMember(
        teammembers.filter(
          (item: TeamMemberProps) =>
            getTeamMemberId(item) === teamMember.toLowerCase()
        )[0]
      );
  }, [teamMember]);

  const changeMember = useCallback(
    (member: TeamMemberProps) => {
      history.push(`/team/${getTeamMemberId(member)}`);
    },
    [history]
  );

  return (
    <div className={classNames(style.wrapper)}>
      <div className={classNames('row around-xs', style.memberSelector)}>
        {teammembers.map((member: TeamMemberProps) => (
          <div
            key={`${member.name}-${member.firstname}`}
            className={classNames('col-xs-6 col-sm-3', style.memberLink, {
              [style.memberLink__active]:
                getTeamMemberId(currentMember) === getTeamMemberId(member)
            })}
            onClick={() => changeMember(member)}>
            {member.firstname} {member.name}
            <br />
            <small>{member.position}</small>
          </div>
        ))}
      </div>
      <TeamMember {...currentMember} />
    </div>
  );
};

export default TeamPage;
