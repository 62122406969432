import React from 'react';
import classNames from 'classnames';
import style from './headline.module.scss';
import FA from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/alvaro.png';

const Headline: React.FC = () => {
  return (
    <div className={classNames(style.wrapper)}>
      <img
        src={logo}
        alt={'alvaro Versicherungsmakler GmbH'}
        className={classNames(style.logo)}
      />
      <NavLink to='/' className={classNames(style.homeicon)}>
        <FA className={classNames(style.icon)} name='home' />
      </NavLink>
    </div>
  );
};

export default Headline;
