export const translateCategory = (category: string) => {
  switch (category.toLowerCase()) {
    case 'gewerbliche sachversicherung':
      return 'Gewerbeversicherung';
    case 'kfz':
      return 'Kfz-Versicherung';
    default:
      return category;
  }
};
