import React from 'react';
import classNames from 'classnames';
import style from './tile.module.scss';

export enum FontSize {
  Small,
  Medium,
  Large
}

interface TileProps {
  title: string;
  image: any;
  fontSize: FontSize;
  backgroundColor?: string;
  onClick?: () => void;
}

const Tile: React.FC<TileProps> = (props: TileProps) => {
  return (
    <div
      className={classNames(style.tile)}
      style={{ backgroundColor: props.backgroundColor ?? 'inherit' }}
      onClick={props.onClick}>
      <img
        src={props.image}
        className={classNames(style.image)}
        alt={props.title}
      />
      <div className={classNames(style.linkcontainer)}>
        <span
          className={classNames(
            style.heading,
            { [style.heading__small]: props.fontSize === FontSize.Small },
            { [style.heading__medium]: props.fontSize === FontSize.Medium },
            { [style.heading__large]: props.fontSize === FontSize.Large }
          )}>
          {props.title}
        </span>
      </div>
    </div>
  );
};

export default Tile;
