import { ApplicationState } from './index';
import { VemaEntry } from '../types/vemaAPI';

export const GetLandingPagesSelector = (state: ApplicationState) => {
  return state.landingPages;
};

export const getLandingPagesByCategorySelector = (category: string) => {
  return (state: ApplicationState) =>
    GetLandingPagesSelector(state).filter(
      (item: VemaEntry) =>
        item.categoryName.toLowerCase() === category.toLowerCase()
    );
};
