import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import style from './portfolio-category-page.module.scss';
import classNames from 'classnames';
import { categoryImageHelper, Size } from '../../utils/image-utils';
import { useSelector } from 'react-redux';
import { getLandingPagesByCategorySelector } from '../../redux/selectors';
import { VemaEntry } from '../../types/vemaAPI';
import { getLandingPageLayoutOptions } from '../../utils/landingpage-utils';
import { ApplicationState } from '../../redux';

interface PortfolioCategoryPageProps {
  category?: string;
}

export const PortfolioCategoryPage: React.FunctionComponent<PortfolioCategoryPageProps> = (
  props: PortfolioCategoryPageProps
) => {
  const category = useMemo(() => decodeURI(props.category ?? ''), [props]);
  const landingPages = useSelector(getLandingPagesByCategorySelector(category));
  const loading = useSelector((state: ApplicationState) => state.loading);
  const history = useHistory();
  const columns: number = useMemo(() => {
    return getLandingPageLayoutOptions(category);
  }, [category]);

  useEffect(() => {
    if (!props.category) {
      history.push('/portfolio');
    }
  }, [props, history]);

  const openLink = useCallback((item: VemaEntry) => {
    window.open(item.destination);
  }, []);

  return (
    <div
      className={classNames(style.wrapper)}
      style={{
        backgroundImage: `url(${categoryImageHelper(category, Size.regular)})`
      }}>
      <div className={classNames(style.content)}>
        <h1 className={classNames(style.heading)}>{category}</h1>
        {loading && (
          <div className={classNames('row', 'around-xs')}>
            <div className={classNames(style.loader)}> </div>
          </div>
        )}
        <div className={classNames('row around-xs', style.container)}>
          {landingPages.map((vemaItem: VemaEntry) => (
            <div
              key={vemaItem.product}
              className={classNames(style.item, {
                'col-xs-12 col-sm-6': columns === 1 || columns === 2,
                'col-xs-12 col-sm-6 col-md-4': columns === 3,
                'col-xs-12 col-sm-6 col-md-3': columns === 4
              })}
              onClick={() => openLink(vemaItem)}>
              <div className={classNames(style.inner)}>{vemaItem.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioCategoryPage;
