import VemaApiResult, { VemaEntry } from '../types/vemaAPI';

export function transfromVemaLandingspages(
  vemaApiResult: VemaApiResult,
  categories: string[]
): VemaEntry[] {
  const result: VemaEntry[] = [];
  for (let category of categories) {
    if (category === 'Private Sachversicherung')
      category = 'private Sachversicherung';
    if (category === 'Gewerbliche Sachversicherung')
      category = 'gewerbliche Sachversicherung';
    if (vemaApiResult[category]) {
      for (const entry of vemaApiResult[category] as VemaEntry[]) {
        result.push(entry);
      }
    }
  }

  return result;
}

export function filterLandingPages(
  landingPages: VemaEntry[],
  category: string
) {
  if (category === 'Alle') return landingPages;
  return landingPages.filter((item: VemaEntry) => {
    return item.categoryName.toLowerCase() === category.toLowerCase();
  });
}

export function transformVemaCategories(
  vemaApiResult: VemaApiResult,
  exclude: string[]
): string[] {
  const categories = vemaApiResult['kategorien'] as string[];
  const result: string[] = [];

  for (const category of categories) {
    if (exclude.indexOf(category) === -1) {
      result.push(category);
    }
  }

  return result;
}
