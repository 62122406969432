import { Actions } from './actions';
import { ApplicationState } from './index';

const initState: ApplicationState = {
  landingPages: [],
  portfolioCategories: [],
  loading: true
};

export const applicationReducer = (
  state: ApplicationState = initState,
  action: Actions
) => {
  switch (action.type) {
    case 'setLandingPageData':
      return {
        ...state,
        landingPages: action.payload.landingPages,
        portfolioCategories: action.payload.categories,
        loading: false
      };
    default:
      return state;
  }
};

export default applicationReducer;
