import gewerbeversicherung from '../assets/images/gewerbeversicherung.jpeg';
import kfzversicherung from '../assets/images/kfz-versicherung.jpeg';
import krankenversicherung from '../assets/images/krankenversicherung.jpeg';
import pflegeversicherung from '../assets/images/pflegeversicherung.jpeg';
import privateSachversicherung from '../assets/images/private-sachversicherung.jpeg';
import altersvorsorge from '../assets/images/altersvorsorge.jpeg';
import lebensversicherung from '../assets/images/lebensversicherung.jpeg';
import gewerbeversicherungSmall from '../assets/images/gewerbeversicherung-small.jpg';
import kfzversicherungSmall from '../assets/images/kfz-versicherung-small.jpg';
import krankenversicherungSmall from '../assets/images/krankenversicherung-small.jpg';
import pflegeversicherungSmall from '../assets/images/pflegeversicherung-small.jpg';
import privateSachversicherungSmall from '../assets/images/private-sachversicherung-small.jpg';
import altersvorsorgeSmall from '../assets/images/altersvorsorge-small.jpg';
import lebensversicherungSmall from '../assets/images/lebensversicherung-small.jpg';

import logo from '../pages/home-page/leistungen.jpeg';

export enum Size {
  small,
  regular
}

export const categoryImageHelper = (category: string, size: Size) => {
  switch (category.toLowerCase()) {
    case 'gewerbliche sachversicherung':
      return size === Size.regular
        ? gewerbeversicherung
        : gewerbeversicherungSmall;
    case 'kfz':
      return size === Size.regular ? kfzversicherung : kfzversicherungSmall;
    case 'krankenversicherung':
      return size === Size.regular
        ? krankenversicherung
        : krankenversicherungSmall;
    case 'pflegeversicherung':
      return size === Size.regular
        ? pflegeversicherung
        : pflegeversicherungSmall;
    case 'private sachversicherung':
      return size === Size.regular
        ? privateSachversicherung
        : privateSachversicherungSmall;
    case 'altersvorsorge':
      return size === Size.regular ? altersvorsorge : altersvorsorgeSmall;
    case 'lebensversicherung':
      return size === Size.regular
        ? lebensversicherung
        : lebensversicherungSmall;
    default:
      return logo;
  }
};
