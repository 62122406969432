import axios, { AxiosResponse } from 'axios';
import VemaApiResult from '../../types/vemaAPI';

class VemaService {
  static instance: VemaService;
  private vemaLandingPagesUrl =
    'https://landingpage.vema-eg.de/ext/connect.php?m=MjExMXw%3D&json=1';
  static getInstance() {
    if (!this.instance) {
      this.instance = new VemaService();
    }
    return this.instance;
  }

  async getLandingpages(): Promise<VemaApiResult> {
    return axios
      .get<VemaApiResult>(this.vemaLandingPagesUrl)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }
}

export default VemaService;
