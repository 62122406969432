import layoutOptions from '../content/landingPageAdjustment.json';

export interface LayoutOptions {
  [key: string]: number;
}

export const getLandingPageLayoutOptions = (category: string): number => {
  //@ts-ignore
  const options: LayoutOptions = layoutOptions;
  return options[getCatggoryObjectName(category)];
};

export const getCatggoryObjectName = (category: string) => {
  switch (category.toLowerCase()) {
    case 'gewerbliche sachversicherung':
      return 'gewerblichesachversicherung';
    case 'private sachversicherung':
      return 'privatesachversicherung';
    default:
      return category.toLowerCase();
  }
};
