import React from 'react';
import classNames from 'classnames';
import style from './footer.module.scss';
import links from '../../content/footerLinks.json';
import { FooterLink } from '../../types/footerLinks';
import { NavLink } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className={classNames('row', style.footer)}>
      {links.map((link: FooterLink) => (
        <div key={link.title} className={'col-xs-6'}>
          <ul className={classNames(style.links)}>
            <strong>{link.title}</strong>
            {link.children?.map((innerLink: FooterLink) => (
              <li className={classNames(style.item)} key={innerLink.title}>
                <a
                  href={innerLink.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classNames(style.link)}>
                  {innerLink.title}
                </a>
              </li>
            ))}
            {link.title.toLowerCase() === 'rechtliches' && (
              <li className={classNames(style.item)}>
                <NavLink
                  to={'/bildnachweis'}
                  className={classNames(style.link)}>
                  Bildnachweis
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      ))}
    </footer>
  );
};

export default Footer;
