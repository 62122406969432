// @ts-ignore
import lucaDreisbusch from '../content/visitenkarten/luca-dreisbusch.vcf';
// @ts-ignore
import holgerDreisbusch from '../content/visitenkarten/holger-dreisbusch.vcf';
// @ts-ignore
import manfredAllig from '../content/visitenkarten/manfred-allig.vcf';
// @ts-ignore
import peterDick from '../content/visitenkarten/alfons-peter-dick.vcf';
// @ts-ignore
import alvaro from '../content/visitenkarten/alvaro.vcf';
import { TeamMemberProps } from '../components/team-member/team-member';

export const getTeamMemberId = (member: TeamMemberProps) => {
  return `${member.firstname}-${member.name}`.toLowerCase();
};

export const getBusinessCard = (id: string) => {
  switch (id) {
    case 'manfred-allig':
      return manfredAllig;
    case 'alfons-peter-dick':
      return peterDick;
    case 'holger-dreisbusch':
      return holgerDreisbusch;
    case 'luca-dreisbusch':
      return lucaDreisbusch;
    default:
      return alvaro;
  }
};
